export default {
  homepage: 'Accueil',
  mentionslegales: 'Mentions légales',
  donneespersonnelles: 'Données personnelles',
  user_id: 'Utilisateur',
  application: 'Application',
  user: {
    role_id: 'Rôle',
    organization_id: 'Structure',
    organization: {
      name: 'Structure'
    }
  },
  utilisateurs: {
    password: 'Mot de passe',
    passwordConfirm: 'Confirmation de mot de passe',
    first_name: 'Prénom',
    last_name: 'Nom',
    organization: {
        index: 'Structure',
        name: 'Structure',
        v: 'Structure',
    },
    email: {
        index: 'Email',
        tooltip: 'L\'e-mail doit être valide et unique. Format attendu : nom{\'@\'}domaine.fr',
    },
    role: {
      admin: 'Administrateur',
      index: 'Rôle',
      manager: 'Gestionnaire',
      user: 'Utilisateur',
    },
    new_access_requests :  {
      toaster: {
        DELETE: {
            success: {
                text: 'La demande d\'accès à l\'application {title} a été supprimé',
                title: 'Demande d\'accès',
            },
        },
      },
      index : 'Demandes d\'accès',
      delete : {
        index : 'Annuler la demande',
        title : 'Annuler la demande',
        text : 'Vous allez annuler la demande d\'accès à l\'application <b>{title}</b>. <br> Souhaitez-vous continuer ?'
      },
    },
    application_roles : {
      toaster: {
        DELETE: {
            success: {
                text: 'L\'accès à l\'application {title} a été supprimé',
                title: 'Accès application',
            },
        },
      },
      delete : {
        index : 'Quitter l\'application',
        title : 'Quitter l\'application',
        // title : '@:utilisateurs.application_roles.index',
        text : 'Vous allez quitter l\'application <b>{title}</b>. <br> Pour y accéder de nouveau, vous devrez faire une nouvelle demande. <br> Souhaitez-vous continuer ?'
      },
      role : {
        none : "Aucun accès",
        read : "Lecture",
        write : "Écriture",
        manage : "Gestion"
      }
    }
  },
  profil: {
    index: 'Mon compte',
    email: {
      index: 'Email',
      tooltip: 'L\'e-mail doit être valide et unique. Format attendu : nom{\'@\'}domaine.fr',
    },
    name: 'Nom',
    last_name: 'Nom',
    first_name: 'Prénom',
    organization: {
      v: 'Structure',
      name: 'Structure',
      index: 'Structure',
    },
    credentials: {
      created_at: 'Compte créé le',
      updated_by: {
        name: 'Modifié par',
      },
    },
    password: 'Mot de passe',
    passwordConfirm: 'Confirmer le mot de passe',
    delete: {
      index: 'Supprimer mon compte',
      title: 'Supprimer mon compte <span class="text-caption text-medium-emphasis">{name}</span>',
      text: 'Souhaitez-vous vraiment supprimer votre compte ? <br/> <ul class="pl-2 pt-2"> <li>La suppression est <b>irrévocable</b>.</li><li><b>Toutes les données</b> renseignées dans les <b>applications</b> seront <b>anonymisées</b>.',
    },
    new_access_requests :  {
      toaster: {
        DELETE: {
            success: {
                text: 'La demande d\'accès à l\'application {title} a été supprimé',
                title: 'Demande d\'accès',
            },
        },
      },
    },
    application_roles : {
      toaster: {
        DELETE: {
            success: {
                text: 'L\'accès à l\'application {title} a été supprimé',
                title: 'Accès application',
            },
        },
      },
    },
    toaster: {
      PUT: {
        success: {
          title: 'Gestion de compte',
          text: 'Votre compte a été modifié avec succès.',
        },
      },
      DELETE: {
        success: {
          title: 'Gestion de compte',
          text: 'Votre compte a été supprimé avec succès.',
        },
      },
    },
  },
  role: {
    user: 'Utilisateur',
    manager: 'Gestionnaire',
    admin: 'Administrateur',
    index: 'Rôle',
  },
}
